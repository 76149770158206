<template>
  <div class="items-wrapper">
    <poster
      v-for="(film, index) in films.sort((a, b) => (a.release_date > b.release_date) ? 1 : -1)"
      :key='index'
      :item='film'
      design="poster"
      type='movie'
    />
  </div>
</template>

<script>
  import api from '@/api'
  import { defineAsyncComponent } from 'vue'
  import { mapState } from 'vuex'
  import { getFirestore, getDocs, query, collection, where } from "firebase/firestore";

  export default {
    data: () => ({
      films: []
    }),
    components: {
      Poster: defineAsyncComponent(() => import('@/components/poster' /* webpackChunkName: 'Poster' */))
    },
    computed: {
      ...mapState('user', ['collections'])
    },
    async activated() {
      if (this.collections.length) {
        let collection = await this.collections.find(x => x.name === this.$route.query.name)
        collection.items.forEach(film => this.getItem(film, collection.type))
      } else {
        let res = await getDocs(query(collection(getFirestore(), 'Collections'), where('name', '==', this.$route.query.name)))
        res.docs[0].data().items.forEach(film => this.getItem(film, res.docs[0].data().type))
      }
    },
    methods: {
      getItem(id, type) {
        api.tmdb.reqItem('film', type, id).then(res => this.films.push(res.data))
      }
    },
    deactivated() {
      // Reset films
      this.films = []
    }
  }
</script>
