<template>
  <section v-if="Object.keys(item).length" class="itemView" ref="itemView">
    <!-- Backdrop -->
    <div class="itemView__header" :style="[item.backdrop_path ? { 'background-image': `url('https://image.tmdb.org/t/p/original/${item.backdrop_path}')` } : { 'background-image': 'none' }]">
      <figure class="posterGroup" v-if="item.poster_path">
        <!-- Poster -->
        <picture>
          <img :src="`https://image.tmdb.org/t/p/w300/${item.poster_path}`" :alt="`Cover art for ${item.name}`">
        </picture>
        <!-- Action: Watch -->
        <button type="button" name="button" @click="watch">Watch</button>
      </figure>

      <div class="details">
        <!-- Title -->
        <h2 v-if="item.name">{{ item.name }}</h2>

        <!-- Metadata -->
        <div class="crumbs">
          <!-- Release Date -->
          <p v-if="item.first_air_date">{{ item.first_air_date.split('-')[0] }}</p>
          <span v-if="item.first_air_date">&#183;</span>
          <!-- Seasons -->
          <p v-if="item.number_of_seasons == 1">{{ item.number_of_seasons }} Season</p>
          <p v-else>{{ item.number_of_seasons }} Seasons</p>
          <span v-if="item.number_of_seasons">&#183;</span>
          <!-- Genres -->
          <div v-if="item.genres" class="genres">
            <p v-for="(genre, index) in item.genres" :key="index">{{ genre.name }}
              <span v-if="index + 1 < item.genres.length"><p>,</p></span>
            </p>
          </div>
          <!-- Runtime -->
          <span v-if="item.episode_run_time">&#183;</span>
          <p v-if="item.episode_run_time">{{ item.episode_run_time[0] }}m</p>
        </div>

        <!-- <h3 v-if="item.networks">{{ item.networks[0].name }}</h3> -->

        <div class="trailerGroup">
          <!-- Popularity Score -->
          <score v-if="item.vote_average" :score="item.vote_average" />
          <!-- Action: Manage Watchlist -->
          <span
            v-if="showWatchlist.includes(item.id)"
            class="action-icon is-active"
            tooltip="Remove from watchlist"
            @click="updateWatchlist({ operation: 'remove', category: 'showWatchlist', type: 'shows', item })">
            <img src="@/assets/icons/watchlist-on.svg" alt="">
          </span>
          <!-- If Limit Reached -->
          <span
            v-else-if="showWatchlist.length == 25"
            class="action-icon not-active"
            tooltip="Limit reached">
            <img src="@/assets/icons/watchlist-off.svg" alt="">
          </span>
          <span
            v-else
            class="action-icon not-active"
            tooltip="Add to watchlist"
            @click="updateWatchlist({ operation: 'add', category: 'showWatchlist', type: 'shows', item })">
            <img src="@/assets/icons/watchlist-off.svg" alt="">
          </span>
          <!-- Action: Manage Favorites -->
          <span
            v-if="favoriteShows.includes(item.id)"
            class="action-icon is-active"
            tooltip="Remove from favorites"
            @click="updateFavorites({ operation: 'remove', category: 'favoriteShows', type: 'shows', item })">
            <img src="@/assets/icons/favorite-fill.svg" alt="">
          </span>
          <!-- If Limit Reached -->
          <span
            v-else-if="favoriteShows.length == 10"
            class="action-icon not-active"
            tooltip="Limit reached">
            <img src="@/assets/icons/favorite-outline.svg" alt="">
          </span>
          <span
            v-else
            class="action-icon not-active"
            tooltip="Add to favorites"
            @click="updateFavorites({ operation: 'add', category: 'favoriteShows', type: 'shows', item })">
            <img src="@/assets/icons/favorite-outline.svg" alt="">
          </span>
          <!-- Action: Manage Watched Lists -->
          <span
            v-if="watched.shows.includes(item.id)"
            class="action-icon is-active"
            @click="updateWatchedLists({ operation: 'remove', type: 'Shows', item })">
            <img src="@/assets/icons/watched.svg" alt="">
          </span>
          <span
            v-else
            class="action-icon not-active"
            tooltip="Watched"
            @click="updateWatchedLists({ operation: 'add', type: 'Shows', item })">
            <img src="@/assets/icons/not-watched.svg" alt="">
          </span>
          <!-- Action: Play Trailer -->
          <span
            v-if="trailer"
            class="action-icon trailer"
            tooltip="View trailer"
            @click="toggleModal">
            <img src="@/assets/icons/view-trailer.svg" alt="">
          </span>
        </div>

        <!-- Overview -->
        <h4 v-if="item.overview">Overview</h4>
        <p v-if="item.overview" class="overview">{{ item.overview }}</p>

        <!-- Status -->
        <p v-if="item.status">Status: {{ item.status }}</p>

        <!-- Next Episode Date -->
        <p v-if="item.next_episode_to_air">Next Episode Air Date: {{ item.next_episode_to_air.air_date }}</p>
      </div>
    </div>

    <!-- Cast -->
    <div v-if="item.credits.cast.length" class="horizontal-wrapper">
       <poster
         v-for="(person, index) in item.credits.cast"
         :key='index'
         :item='person'
         design='poster'
         type='person'
       />
    </div>

    <!-- Recommendations -->
    <template v-if="item.recommendations.results.length">
      <p class="recommendations">You might also like</p>
      <div class="horizontal-wrapper">
        <poster
          v-for="(film, index) in item.recommendations.results"
          :key='index'
          :item='film'
          design='poster'
          type='show'
        />
      </div>
    </template>
  </section>
</template>

<script>
  import api from '@/api'
  import { defineAsyncComponent } from 'vue'
  import { mapState, mapGetters, mapActions } from 'vuex'
  import { getAnalytics, logEvent } from "firebase/analytics";

  export default {
    name: 'Show',
    data: () => ({
      item: {}
    }),
    components: {
      Poster: defineAsyncComponent(() => import('@/components/poster' /* webpackChunkName: 'Poster' */)),
      Score: defineAsyncComponent(() => import('@/components/score' /* webpackChunkName: 'Score' */))
    },
    computed: {
      ...mapState('user', ['user', 'watched']),
      ...mapGetters('user', { favoriteShows: 'getFavoriteShows', showWatchlist: 'getShowWatchlist' }),

      trailer() {
        return this.item.videos?.results.find(x => x.name === 'Official Trailer') || this.item.videos?.results.find(x => x.type === 'Trailer')
      }
    },
    activated() {
      // Watch for route id
      let removeWatcher = this.$watch('$route.query.id', (id) => {
        // Reset item
        this.item = {}
        // Request item on desired route
        if (this.$route.name === 'Show') this.getItem(id)
        // Remove watcher
        else removeWatcher()
      }, { immediate: true })
    },
    methods: {
      ...mapActions('user', ['updateFavorites', 'updateWatchlist', 'updateWatchedLists']),
      ...mapActions('utilities', ['logActivity']),

      getItem(id) {
        api.tmdb.reqItem('film', 'tv', id).then(res => this.item = res.data)
      },
      async watch() {
        await logEvent(getAnalytics(), 'watched_show', {
          username: this.user.username,
          item: this.item.name
        })

        if (this.item.external_ids.imdb_id) {
          this.$router.push({ name: 'Watch', query: { type: 'show', id: this.$route.query.id, tag: this.item.external_ids.imdb_id, season: 1, episode: 1 }})
        } else {
          this.$router.push({ name: 'Watch', query: { type: 'show', id: this.$route.query.id, season: 1, episode: 1 }})
        }

        // Log activity in database
        this.logActivity({
          created: new Date().getTime(),
          activity: 'Watched show',
          item: this.item.name,
          user: { name: this.user.username, id: this.user.id }
        })
      },
      toggleModal() {
        this.$store.commit('utilities/TOGGLE_MODAL', { type: 'trailer', data: this.trailer.key })
      }
    },
    deactivated() {
      this.$store.commit('utilities/TOGGLE_MODAL')
    }
  }
</script>
